import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout title="Page Not Found">
    <Seo title="Page Not Found" />
    <p>Sorry, this page doesn't seem to exist.</p>
    {/* I could add something fun here. */}
  </Layout>
)

export default NotFoundPage